import React, { useContext } from "react";
import { IconButton } from "@material-ui/core";
import { Brightness2, Brightness6 } from "@material-ui/icons";

import { ThemeContext } from "./ThemeContext";

const ThemeSwitch: React.FC = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    switch (theme) {
      case "light":
        setTheme("dark");
        break;
      case "dark":
        setTheme("light");
        break;
    }
  };

  const title = `Switch to ${theme === "dark" ? "light" : "dark"} mode`;

  return (
    <IconButton onClick={toggleTheme} color="inherit" title={title}>
      {theme === "light" && <Brightness2 color="inherit" />}
      {theme === "dark" && <Brightness6 color="inherit" />}
    </IconButton>
  );
};

export default ThemeSwitch;

import { DoctrineDetails, FitDetails, ShipDetails } from "../apiTypes";
import { DOCTRINE_URL_SCHEMA, getDoctrineUrl } from "./getDoctrineLink";

export const DOCTRINE_FIT_URL_SCHEMA = `${DOCTRINE_URL_SCHEMA}/:shipSlug/:fitSlug`;

interface IParams {
  shipSlug: ShipDetails["slug"];
  fitSlug: FitDetails["slug"];
  doctrineSlug: DoctrineDetails["slug"];
}

export function getDoctrineFitUrl({
  shipSlug,
  fitSlug,
  doctrineSlug,
}: IParams) {
  return `${getDoctrineUrl(doctrineSlug)}/${shipSlug}/${fitSlug}`;
}

import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter,
  Link as RouterLink,
  Route,
  Switch,
} from "react-router-dom";
import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Home } from "@material-ui/icons";
import { blueGrey, grey } from "@material-ui/core/colors";

import DoctrinesList from "./DoctrinesList";
import ShipFitPage from "./ShipFitPage";
import UserMenu from "./UserMenu";
import SingleDoctrinePage from "./SingleDoctrinePage";
import { DOCTRINE_URL_SCHEMA } from "./lib/getDoctrineLink";
import { DOCTRINE_FIT_URL_SCHEMA } from "./lib/getFitLink";
import ThemeSwitch from "./ThemeSwitch";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Box
          component={AppBar}
          position="sticky"
          bgcolor={blueGrey[800]}
          color={grey[100]}
        >
          <Toolbar>
            <IconButton
              component={RouterLink}
              to="/"
              aria-label="Home"
              color="inherit"
            >
              <Home />
            </IconButton>
            <Typography variant="h6" component="p">
              Fit Comparator (BETA)
            </Typography>
            <Box component="p" flexGrow={1}></Box>
            <ThemeSwitch />
            <UserMenu />
          </Toolbar>
        </Box>
        <Switch>
          <Route exact path="/">
            <DoctrinesList />
          </Route>
          <Route path={DOCTRINE_FIT_URL_SCHEMA}>
            <ShipFitPage />
          </Route>
          <Route path={DOCTRINE_URL_SCHEMA}>
            <SingleDoctrinePage />
          </Route>
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;

import React from "react";
import { withRouter } from "react-router-dom";
import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ErrorIcon from "@material-ui/icons/Error";

import { LOGIN_URL } from "./apiTypes";
import useUser from "./lib/useUser";
import axios from "./lib/axios";

interface History {
  push: Function;
}

type PropType = {
  history: History;
};

function UserMenu(props: PropType) {
  const {
    isLoading,
    isError,
    data: currentUser,
    error,
  } = useUser({
    retry: false,
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const handleMenu: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const triggerLogout = () => {
    axios.post("/oidc/logout/").then(() => {
      window.location.replace(LOGIN_URL);
    });
  };

  if (isError) {
    if (error?.response?.status === 401) {
      window.location.replace(LOGIN_URL);
    }
    return <ErrorIcon color="inherit" />;
  }

  if (isLoading) {
    return <CircularProgress color="inherit" />;
  }

  return (
    <>
      {currentUser !== undefined && (
        <div>
          <IconButton
            aria-label="Account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem>Hi {currentUser.username}!</MenuItem>
            {currentUser.is_admin && (
              <MenuItem>
                <Button href="/admin">Admin</Button>
              </MenuItem>
            )}
            <MenuItem>
              <Button onClick={triggerLogout}>Refresh auth</Button>
            </MenuItem>
          </Menu>
        </div>
      )}
    </>
  );
}

export default withRouter(UserMenu);

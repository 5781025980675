import React, { useMemo, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { ThemeProvider, CssBaseline, createMuiTheme } from "@material-ui/core";
import { blueGrey, amber } from "@material-ui/core/colors";

type AvailableTheme = "light" | "dark";

export const ThemeContext = React.createContext<{
  theme: AvailableTheme;
  setTheme: React.Dispatch<React.SetStateAction<AvailableTheme>>;
}>({
  theme: "light",
  setTheme: () => {},
});

const Wrapper: React.FC = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [theme, setTheme] = useState<AvailableTheme>(
    prefersDarkMode ? "dark" : "light"
  );

  const activeTheme = useMemo(() => {
    return createMuiTheme({
      typography: {
        h1: {
          fontSize: "3rem",
        },
        h2: {
          fontSize: "2rem",
        },
        h3: {
          fontSize: "1.5rem",
        },
        h4: {
          fontSize: "1.25rem",
        },
        h5: {
          fontSize: "1rem",
        },
        h6: {
          fontSize: "1rem",
        },
      },
      palette: {
        type: theme,
        primary: {
          main: blueGrey[800],
        },
        secondary: {
          main: amber[400],
        },
      },
    });
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider theme={activeTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default Wrapper;

import axios from "./axios";
import { TagDetails } from "../apiTypes";
import { useQuery } from "react-query";
import { AxiosError } from "axios";

const tagColorList = [
  "aquamarine",
  "bisque",
  "beige",
  "darkseagreen",
  "greenyellow",
  "lightcyan",
  "mediumpurple",
];

export type TagDetailsPlusColor = TagDetails & {
  color: string;
};

const getTagsList = async () => {
  const { data } = await axios.get<TagDetails[]>("/api/tags");
  let output: Record<string, TagDetailsPlusColor> = {};
  let colorIndex = 0;
  for (const tag of data) {
    if (colorIndex >= tagColorList.length) {
      colorIndex = 0;
    }
    output[tag.name] = Object.assign(tag, { color: tagColorList[colorIndex] });
    ++colorIndex;
  }
  console.log(output);
  return output;
};

export default function usePost() {
  return useQuery<Record<string, TagDetailsPlusColor>, AxiosError>(
    "tags",
    getTagsList
  );
}

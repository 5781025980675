import React from "react";
import { useParams } from "react-router-dom";
import { Container, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import SingleDoctrine from "./DoctrinesListItem";

const SingleDoctrinePage: React.FC = () => {
  const { doctrineSlug }: Record<string, string> = useParams();

  return (
    <Box component={Container} my={2} role="main">
      <Typography variant="srOnly" component="h1">
        Doctrine view
      </Typography>
      <SingleDoctrine doctrine={{ slug: doctrineSlug, name: "" }} />
    </Box>
  );
};

export default SingleDoctrinePage;

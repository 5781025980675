import { useQuery, UseQueryOptions } from "react-query";
import axios, { AxiosError } from "axios";

import { DoctrineFitDetails } from "../apiTypes";

interface IParams {
  shipSlug: string;
  fitSlug: string;
  doctrineSlug: string;
}

const getDoctrineFit = async ({ shipSlug, fitSlug, doctrineSlug }: IParams) => {
  const { data } = await axios.get<DoctrineFitDetails>(
    `/api/doctrines/${doctrineSlug}/fits/${shipSlug}/${fitSlug}`
  );
  return data;
};

export default function usePost(
  { shipSlug, fitSlug, doctrineSlug }: IParams,
  options?: UseQueryOptions<DoctrineFitDetails, AxiosError>
) {
  return useQuery<DoctrineFitDetails, AxiosError>(
    ["fit", `${doctrineSlug}/${shipSlug}/${fitSlug}`],
    () => getDoctrineFit({ shipSlug, fitSlug, doctrineSlug }),
    options
  );
}

import React from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Warning from "@material-ui/icons/Warning";
import LinkIcon from "@material-ui/icons/Link";
import { CopyToClipboard } from "react-copy-to-clipboard";
import BBCode from "@bbob/react/es/Component";
import reactPreset from "@bbob/preset-react/es";
import { crlf, LF } from "crlf-normalize";

import ShipFitValidator from "./ShipFitValidator";
import useDoctrineFit from "./lib/useDoctrineFit";
import { getDoctrineUrl } from "./lib/getDoctrineLink";

function ShipFitPage() {
  const { doctrineSlug, shipSlug, fitSlug }: Record<string, string> =
    useParams();

  const { data: doctrineFit, isLoading } = useDoctrineFit({
    doctrineSlug,
    shipSlug,
    fitSlug,
  });

  if (isLoading) {
    return <CircularProgress color="inherit" />;
  }

  if (!doctrineFit) {
    return <p>We could not find the right fit in the doctrine</p>;
  }

  return (
    <Container role="main">
      <Box mt={4} id="top">
        <Typography variant="h1">
          {doctrineFit.fit.ship.name}-{doctrineFit.fit.name}
        </Typography>
      </Box>
      {doctrineFit.only_if_fc_calls_for_it && (
        <Chip
          label="FC Call Only"
          icon={<Warning fontSize="small" />}
          color="secondary"
        />
      )}

      <Box my={2}>
        <ButtonGroup variant="text" color="primary">
          <Button component={Link} href="#doctrine">
            Doctrine
          </Button>
          <Button component={Link} href="#role">
            Role
          </Button>
          <Button component={Link} href="#fit">
            Fit
          </Button>
          <Button component={Link} href="#validator">
            Validator
          </Button>
        </ButtonGroup>
      </Box>

      <Box py={4} id="doctrine">
        <Typography variant="h2" gutterBottom>
          Doctrine:{" "}
          <Link component={RouterLink} to={getDoctrineUrl(doctrineSlug)}>
            {doctrineFit.doctrine_name}
          </Link>
          <IconButton component="a" href="#doctrine">
            <LinkIcon />
          </IconButton>
        </Typography>
        {doctrineFit.doctrine_description && (
          <Box component={Typography} whiteSpace="pre-wrap">
            <BBCode plugins={[reactPreset()]}>
              {doctrineFit.doctrine_description}
            </BBCode>
          </Box>
        )}
      </Box>

      <Divider />

      <Box py={4} id="role">
        <Typography variant="h2" gutterBottom>
          Role: {doctrineFit.role_name}
          <IconButton component="a" href="#role">
            <LinkIcon />
          </IconButton>
        </Typography>
        {doctrineFit.role_comments && (
          <Box component={Typography} whiteSpace="pre-wrap">
            <BBCode plugins={[reactPreset()]}>
              {doctrineFit.role_comments}
            </BBCode>
          </Box>
        )}
      </Box>

      <Divider />

      <Box py={4} id="fit">
        <Typography variant="h2" gutterBottom>
          Fit
          <IconButton component="a" href="#fit">
            <LinkIcon />
          </IconButton>
        </Typography>
        {doctrineFit.fit.comments && (
          <Box component={Typography} whiteSpace="pre-wrap" mb={1}>
            <BBCode plugins={[reactPreset()]}>
              {doctrineFit.fit.comments}
            </BBCode>
          </Box>
        )}
        <Box component={Paper} p={1} my={2} maxHeight={200} overflow="auto">
          <pre>{doctrineFit.fit.eft}</pre>
        </Box>
        <CopyToClipboard text={crlf(doctrineFit.fit.eft, LF)}>
          <Button variant="contained">Copy to clipboard</Button>
        </CopyToClipboard>
      </Box>

      <Divider />

      <Box py={4} id="validator">
        <Typography variant="h2" gutterBottom>
          Validator
          <IconButton component="a" href="#validator">
            <LinkIcon />
          </IconButton>
        </Typography>
        <ShipFitValidator
          referenceFit={doctrineFit.fit}
          validationUrl={`/api/validate/${doctrineSlug}/fits/${shipSlug}/${fitSlug}`}
        />
      </Box>
    </Container>
  );
}

export default ShipFitPage;

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  List,
  ListItem,
  Typography,
  Link,
  Paper,
  useTheme,
  ListSubheader,
  Divider,
  Chip,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { Warning } from "@material-ui/icons";

import { NameAndSlug } from "./apiTypes";
import useDoctrine from "./lib/useDoctrine";
import getTagsList from "./lib/getTagsList";
import { getDoctrineUrl } from "./lib/getDoctrineLink";
import { getDoctrineFitUrl } from "./lib/getFitLink";
import ShipAvatar from "./ShipAvatar";

interface Props {
  doctrine: NameAndSlug;
}

function SingleDoctrine({ doctrine }: Props) {
  const { data: doctrineData } = useDoctrine(doctrine.slug);
  const { data: tagData } = getTagsList();
  const theme = useTheme();

  if (!doctrineData) return null;

  return (
    <Box
      component={Paper}
      mb={3}
      bgcolor={theme.palette.secondary.main}
      color={theme.palette.secondary.contrastText}
    >
      <Box p={1}>
        <Typography variant="h5" component="h2">
          <Link
            component={RouterLink}
            to={getDoctrineUrl(doctrineData.slug)}
            color="inherit"
          >
            {doctrineData.name}
          </Link>
        </Typography>
        <Box>
          {doctrineData.tags.map((tag, tagIndex) => (
            <Tooltip
              title={tagData![tag].comments}
              enterDelay={500}
              leaveDelay={200}
            >
              <Chip
                label={tag}
                color="secondary"
                style={{ backgroundColor: tagData![tag].color }}
              />
            </Tooltip>
          ))}
        </Box>
      </Box>
      <Box component={Paper} p={1}>
        {doctrineData.roles.map((role, roleIndex) => (
          <Box key={`${doctrine.slug}-${role.name}`}>
            {roleIndex > 0 && <Divider />}
            <List subheader={<ListSubheader>{role.name}</ListSubheader>}>
              {role?.fits.map((fit) => (
                <ListItem
                  key={`${fit.fit.ship.slug}-${fit.fit.slug}`}
                  component="li"
                  alignItems="flex-start"
                >
                  <ListItemAvatar>
                    <ShipAvatar ship={fit.fit.ship} />
                  </ListItemAvatar>
                  <Box flexGrow={1}>
                    <Link
                      component={RouterLink}
                      to={getDoctrineFitUrl({
                        doctrineSlug: doctrine.slug,
                        shipSlug: fit.fit.ship.slug,
                        fitSlug: fit.fit.slug,
                      })}
                      color="inherit"
                    >
                      <ListItemText
                        primary={fit.fit.ship.name}
                        secondary={fit.fit.name}
                      />
                    </Link>
                  </Box>
                  <Box>
                    {fit.only_if_fc_calls_for_it && (
                      <Chip
                        label="FC Call Only"
                        icon={<Warning fontSize="small" />}
                        color="secondary"
                        size="small"
                      />
                    )}
                    {fit.tags.map((tag, tagIndex) => (
                      <Tooltip
                        title={tagData![tag].comments}
                        enterDelay={500}
                        leaveDelay={200}
                      >
                        <Chip
                          label={tag}
                          color="secondary"
                          style={{ backgroundColor: tagData![tag].color }}
                          size="small"
                        />
                      </Tooltip>
                    ))}
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default SingleDoctrine;

import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import SingleDoctrine from "./DoctrinesListItem";
import useDoctrines from "./lib/useDoctrines";

function DoctrinesList() {
  const { isLoading, data: doctrines } = useDoctrines();

  if (isLoading) return <div>Loading...</div>;

  if (!doctrines) {
    return <p>No doctrine was found</p>;
  }

  return (
    <Container role="main" maxWidth="lg">
      <Typography variant="srOnly" component="h1">
        Doctrines List
      </Typography>
      <Box mt={2}>
        <Grid container spacing={2}>
          {doctrines.map((doctrine) => (
            <Grid item xs={12} sm={6} lg={4} key={doctrine.slug}>
              <SingleDoctrine doctrine={doctrine} key={doctrine.name} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default DoctrinesList;

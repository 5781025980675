import { useQuery, UseQueryOptions } from "react-query";
import axios from "./axios";
import { AxiosError } from "axios";

import { DoctrineDetails } from "../apiTypes";

const getDoctrineBySlug = async (doctrineSlug: string) => {
  const { data } = await axios.get<DoctrineDetails>(
    `/api/doctrines/${doctrineSlug}`
  );
  return data;
};

export default function usePost(
  doctrineSlug: string,
  options?: UseQueryOptions<DoctrineDetails, AxiosError>
) {
  return useQuery<DoctrineDetails, AxiosError>(
    ["doctrine", doctrineSlug],
    () => getDoctrineBySlug(doctrineSlug),
    options
  );
}

import React from "react";
import { Avatar, makeStyles } from "@material-ui/core";
import { amber, blue, teal, deepOrange } from "@material-ui/core/colors";

import { FitReference } from "./apiTypes";

const useStyles = makeStyles((theme) => ({
  4: {
    color: theme.palette.getContrastText(amber[100]),
    backgroundColor: amber[100],
  },
  1: {
    color: theme.palette.getContrastText(blue[100]),
    backgroundColor: blue[100],
  },
  8: {
    color: theme.palette.getContrastText(teal[100]),
    backgroundColor: teal[100],
  },
  2: {
    color: theme.palette.getContrastText(deepOrange[100]),
    backgroundColor: deepOrange[100],
  },
}));

export default function ShipAvatar({ ship }: { ship: FitReference["ship"] }) {
  const classes = useStyles();

  return (
    <Avatar
      alt={ship.name}
      src={
        ship.eve_type_id
          ? `//images.evetech.net/types/${ship.eve_type_id}/icon`
          : undefined
      }
      className={
        ship.eve_race_id && ship.eve_race_id in classes
          ? classes[ship.eve_race_id]
          : ""
      }
    >
      {ship.name.slice(0, 1)}
    </Avatar>
  );
}

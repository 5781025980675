import { useQuery, UseQueryOptions } from "react-query";
import axios from "./axios";
import { AxiosError } from "axios";

import { User } from "../apiTypes";

const getUser = async () => {
  const { data } = await axios.get<User>(`/api/user/`);

  return data;
};

export default function useUser(options?: UseQueryOptions<User, AxiosError>) {
  return useQuery<User, AxiosError>(["user"], () => getUser(), options);
}

import React, { useState } from "react";
import { useFormik } from "formik";
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Alert, AlertTitle } from "@material-ui/lab";

import { FitDetails } from "./apiTypes";
import axios from "./lib/axios";
import { parseDifference, Diff } from "./lib/validatorDifferenceParser";
import FitDifferenceViewer from "./FitDifferenceViewer";
import CopyToClipboard from "react-copy-to-clipboard";
import { compareFits } from "./lib/fitparser";
import crlf, { LF } from "crlf-normalize";

const normaliseLineEndings = (fit: string) => crlf(fit, LF);
interface Props {
  referenceFit: FitDetails;
  validationUrl: string;
}

const ShipFitValidator: React.FC<Props> = ({ referenceFit, validationUrl }) => {
  const formik = useFormik({
    initialValues: { testFit: "" },
    onSubmit: async (values) => {
      // Reset the state
      setResults(null);
      setError(null);
      //setDifference(null);

      if (referenceFit) {
        try {
          setResults(
            compareFits(
              normaliseLineEndings(referenceFit.eft),
              normaliseLineEndings(values.testFit),
              referenceFit.replacement_modules
            )
          );
        } catch (error) {
          switch (error.message) {
            case "Wrong shiptype":
              setError(
                "The pasted ship doesn't match the reference ship. Please double-check you're using the right hull."
              );
              break;

            default:
              setError(error.message);
              break;
          }
        } finally {
          document.querySelector("#validator-results")?.scrollIntoView();
        }
      }
    },
  });

  const [results, setResults] = useState<null | {
    missing: string[];
    extras: string[];
  }>(null);

  //const [fitDifference, setDifference] = useState<null | Diff>(null);

  const [error, setError] = useState<null | string>(null);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={2}>
        <Typography>
          Export your current fit using the "Copy to Clipboard" option from the
          ingame Fitting window and paste it below.
        </Typography>
      </Box>
      <Grid container spacing={2} className="comparisonWrapper">
        <Grid item xs={6}>
          <TextField
            name="testFit"
            label="Your fit"
            id="testFit"
            value={formik.values.testFit}
            onChange={formik.handleChange}
            multiline
            variant="outlined"
            fullWidth
            rows={10}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <Box textAlign="right">
            <Button type="submit" variant="contained" color="secondary">
              Validate my fit
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12} />
        <div id="validator-results" />
        {results && results.extras.length > 0 && (
          <Grid item xs={12}>
            <Box component={Paper} height="100%">
              <Alert severity="warning">
                <AlertTitle>Extra items</AlertTitle>
                You have items that do not belong in the doctrine, use at your
                own risk. See below for the details.
              </Alert>
              <Box component="pre" p={1}>
                {results.extras.map((line) => `${line}\n`)}
              </Box>
            </Box>
          </Grid>
        )}
        {results && results.missing.length > 0 && (
          <Grid item xs={12}>
            <Box component={Paper} height="100%">
              <Alert severity="error">
                <AlertTitle>Missing items</AlertTitle>
                You have missing items in your fit. Use the button below to copy
                what's missing and paste it in the Multibuy window.
              </Alert>
              <Box padding={2}>
                <CopyToClipboard text={results.missing.join(`\n`)}>
                  <Button variant="contained">
                    Copy the shopping list to clipboard
                  </Button>
                </CopyToClipboard>
                <Box component="pre">
                  {results.missing.map((line) => `${line}\n`)}
                </Box>
              </Box>
            </Box>
          </Grid>
        )}
        {results &&
          results.extras.length === 0 &&
          results.missing.length === 0 && (
            <Grid item xs={12}>
              <Box component={Paper}>
                <Alert severity="success">
                  <AlertTitle>OP Successs</AlertTitle>
                  Your fit is exactly as it should be.
                </Alert>
              </Box>
            </Grid>
          )}
        {error && (
          <Grid item xs={12}>
            <Box component={Paper}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            </Box>
          </Grid>
        )}

        {
          // Commenting out as the backend is broken
          /*<Grid item xs={12}>
          <FitDifferenceViewer fitDifference={fitDifference} />
        </Grid>*/
        }
      </Grid>
    </form>
  );
};

export default ShipFitValidator;

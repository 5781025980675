import { useQuery, UseQueryOptions } from "react-query";
import axios from "./axios";
import { AxiosError } from "axios";

import { NameAndSlug } from "../apiTypes";

const getDoctrines = async () => {
  const { data } = await axios.get<NameAndSlug[]>("/api/doctrines");
  return data;
};

export default function useDoctrines(
  options?: UseQueryOptions<NameAndSlug[], AxiosError>
) {
  return useQuery<NameAndSlug[], AxiosError>(
    "doctrines",
    getDoctrines,
    options
  );
}

import { reject, difference, has, intersection } from "lodash";

const SHIP_REGEX = /\[([\w\s]+),\s*(.+)\]/i;
const CARGO_ITEM_REGEX = /(x[0-9]+)$/;
const MODULE_WITH_CHARGE_REGEX = /^([\w\s-]+), ([\w\s]+)/i;
const EMPTY_SLOT_REGEX = /\[empty [a-z]+ slot\]/i;

export function parseShipType(eftText: string) {
  const matches = SHIP_REGEX.exec(eftText);
  return matches ? matches[1] : null;
}

export function parseFitName(eftText: string) {
  const matches = SHIP_REGEX.exec(eftText);
  return matches ? matches[2] : null;
}

export function parseFitSlots(eftText: string) {
  const blocks = eftText
    .trim()
    .substring(eftText.indexOf("\n") + 1)
    .split(/\n\n/gm);

  const [lows, mediums, highs, rigs, subsystems, drones, cargo] = blocks.map(
    (block) => block.trim().split(/\n/gm)
  );
  return { lows, mediums, highs, rigs, subsystems, drones, cargo };
}

export function getModulesAndCargoList(eftText: string) {
  const list = eftText
    .trim()
    .substring(eftText.indexOf("]\n") + 1)
    .split(/\n/);
  return reject(list, (line) => line.trim() === "");
}

export function isCargoItem(eftLine: string) {
  return CARGO_ITEM_REGEX.test(eftLine);
}

export function isEmptySlot(eftLine: string) {
  return EMPTY_SLOT_REGEX.test(eftLine);
}

export function parseCargoItem(eftLine: string) {
  const matches = CARGO_ITEM_REGEX.exec(eftLine);

  return matches
    ? {
        name: eftLine.replace(CARGO_ITEM_REGEX, "").trim(),
        quantity: parseInt(matches[0].replace(/^x/, "").trim(), 10),
      }
    : null;
}

export function getItemsQuantity(eftText: string) {
  const rawItems: Array<{ name: string; quantity: number } | null> = [];

  getModulesAndCargoList(eftText).forEach((line) => {
    line = line.replace("/OFFLINE", "").trim();

    if (isEmptySlot(line)) {
      return;
    } else if (isCargoItem(line)) {
      rawItems.push(parseCargoItem(line));
    } else if (MODULE_WITH_CHARGE_REGEX.test(line)) {
      const matches = line.split(",");
      if (matches) {
        rawItems.push({ name: matches[0].trim(), quantity: 1 });
        rawItems.push({ name: matches[1].trim(), quantity: 1 });
      }
    } else {
      rawItems.push({ name: line.trim(), quantity: 1 });
    }
  });

  const fitItems: Record<string, number> = {};

  rawItems.forEach((item) => {
    if (item) {
      if (!has(fitItems, item.name)) {
        fitItems[item.name] = item.quantity;
      } else {
        fitItems[item.name] += item.quantity;
      }
    }
  });

  return fitItems;
}

interface ReplacementDefinition {
  original_module: string;
  replacement_modules: string[];
}

export function compareFits(
  referenceFit: string,
  testedFit: string,
  replacements: ReplacementDefinition[] = []
) {
  if (parseShipType(referenceFit) !== parseShipType(testedFit)) {
    throw new Error("Wrong shiptype");
  }

  const referenceItems = getItemsQuantity(referenceFit);
  const testItems = getItemsQuantity(testedFit);

  // Transform test items that match a replacement
  replacements.forEach(({ original_module, replacement_modules }) => {
    const modulesToReplace = intersection(
      Object.keys(testItems),
      replacement_modules
    );

    modulesToReplace.forEach((moduleName) => {
      if (testItems[original_module]) {
        testItems[original_module] += testItems[moduleName];
        delete testItems[moduleName];
      } else {
        testItems[original_module] = testItems[moduleName];
        delete testItems[moduleName];
      }
    });
  });

  const missing = [];
  const extras = [];

  for (const itemName in referenceItems) {
    if (!testItems[itemName]) {
      missing.push(`${itemName} ${referenceItems[itemName]}`);
    } else if (testItems[itemName] < referenceItems[itemName]) {
      missing.push(
        `${itemName} ${referenceItems[itemName] - testItems[itemName]}`
      );
    } else if (testItems[itemName] > referenceItems[itemName]) {
      extras.push(
        `${itemName} ${testItems[itemName] - referenceItems[itemName]}`
      );
    }
  }

  difference(Object.keys(testItems), Object.keys(referenceItems)).forEach(
    (itemName) => {
      extras.push(`${itemName} ${testItems[itemName]}`);
    }
  );

  return { missing, extras };
}

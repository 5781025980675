// Keep up to date with schema defs in fit-validator-backend/src/fit_validator/api.py

// API Schema types

export const LOGIN_URL = "/oidc/authenticate/";

export type User = {
  username: string;
  is_admin: boolean;
};

export type NameAndSlug = {
  name: string;
  slug: string;
};

export type ShipReference = NameAndSlug & {
  eve_type_id: number;
  eve_race_id?: number;
};

export type ShipDetails = NameAndSlug & {
  fits: NameAndSlug[];
};

export type FitReference = NameAndSlug & {
  ship: ShipReference;
};

export type ReplacementModuleGroup = {
  original_module: string;
  replacement_modules: string[];
};

export type FitDetails = FitReference & {
  eft: string;
  comments: string;

  replacement_modules: ReplacementModuleGroup[];
};

export type DoctrineFit = {
  fit: FitReference;
  only_if_fc_calls_for_it: boolean;
  comments: string;
  tags: string[];
};

export type DoctrineRole = {
  name: string;
  fits: DoctrineFit[];
  comments: string;
};

export type DoctrineDetails = NameAndSlug & {
  roles: DoctrineRole[];
  description: string;
  tags: string[];
};

export type DoctrineFitDetails = DoctrineFit & {
  fit: FitDetails;
  doctrine_name: string;
  doctrine_description: string;
  role_name: string;
  role_comments: string;
};

export type TagDetails = {
  name: string;
  comments: string;
  describes_doctrine: boolean;
  describes_fit: boolean;
};
